.text-transition {
  transition: color 0.1s ease-in-out;
}

.text-transition:hover {
  color: #26a5ec;
}

.editIconImage {
  opacity: 100%;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  border: 3px solid #26a5ec;
}

.editIconImage:hover {
  opacity: 30%;
}

.editIcon {
  opacity: 0%
}

.editIcon:hover {
  opacity: 75%
}